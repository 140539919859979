import Row from 'react-bootstrap/Row'

import ShopCard from './ShopCard'

export default function Bookings({ shops }) {
    return (
        // row containing shop cards
        <Row className='justify-content-center mx-0'>
            <Row className='text-white shadow-lg bg-secondary border border-white rounded text-center w-75'>
                <h6 className='py-3'>
                    Our shops are open from 9am to 6pm weekdays, 9am to 5pm on Saturday, and we are closed on Sundays.
                     Lunch can vary as it depends on the appointments for that day.
                </h6>
            </Row>
            <Row className='justify-content-center'>
                {shops.map((shop) => {
                    return <ShopCard 
                            key={shop.id}
                            town={shop.town} 
                            img={shop.img}
                            text={shop.text}
                            walkIn={shop.walkIn}
                            bookOnline={shop.bookOnline}
                            mapLink={shop.mapLocation}/>
                })}
            </Row>
        </Row>
    )
}
