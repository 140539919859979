/*-----

    IMPORTS

-----*/

// Bootstrap Components
import Row from 'react-bootstrap/Row'
import Image from 'react-bootstrap/Image'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
// images
import LogoSRC from '../assets/images/presentation/logo.jpeg'


/*-----

    Header Component

-----*/
export default function Header() {
    return (
        // Row container of the header component
        <Row id='header-row' className='m-0'>
            {/* Bootstrap Navbar */}
            <Navbar bg='dark' variant='dark' expand='sm' className='p-0'>
                {/* Navbar Brand */}
                <Navbar.Brand className='text-white ps-2 py-1 m-0 banner'>
                    {/* Navbar Image */}
                    <Image 
                    src={LogoSRC}
                    alt='circle with silhouette of bearded man facing sideways'
                    fluid
                    roundedCircle
                    className='logo me-md-5 me-sm-3 me-xs-5'
                    />
                    {/* Navbar Title */}
                    <h1 className='d-inline-block ms-4 me-sm-2 title align-middle'>
                        Wild Barber
                    </h1>
                </Navbar.Brand>
                {/* Navbar toggle btn */}
                <Navbar.Toggle aria-controls='navbar-nav' className='navbar-btn me-2'/>
                {/* collapsable section */}
                <Navbar.Collapse id='navbar-nav'>
                    {/* Navbar Navigations */}
                    <Nav className='me-md-2 ms-auto text-center'>
                        {/* Navbar Links */}
                        <Nav.Link href='#booking-section'>
                            Make A Booking                  
                        </Nav.Link>
                        {/* <Nav.Link href='#course-section'>
                            Check Our Courses
                        </Nav.Link> */}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </Row>
    )
}