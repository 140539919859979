/*---------

    IMPORTS

---------*/
// Custom App Stylesheet
import './App.css';
// Bootstrap Stylesheet
import 'bootstrap/dist/css/bootstrap.min.css';
// Bootstrap Components
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Image from 'react-bootstrap/Image'
// images of shop
//import MereShop from './assets/images/shops/coming soon.png'
import SwindonShop from './assets/images/shops/swindon store.png'
// other images
import HomeIMG from './assets/images/presentation/Home Page Banner Image.jpeg'
import OwnerSRC from './assets/images/owner/owner 1.jpeg'
import OwnerGirlSRC from './assets/images/owner/owner 2.png'
import OwnerHairSRC from './assets/images/owner/haircut 7.jpeg'
// import ProductSectionTitleIMG from './assets/images/presentation/line of products.jpeg'
// custom components
import Header from './components/Header'
import SectionTitle from './components/SectionTitle';
import AboutOwner from './components/AboutOwner';
import Booking from './components/Bookings'
// import Products from './components/Products';
import Courses from './components/Courses'
import Footer from './components/Footer'
// import Gallery from './components/Gallery';


/*---------

    VARIABLES

---------*/
// Array of objects, details about each shop
const shopListWithDetails = [
  {
    id: 1,
    town: "Swindon",
    img: {
      src: SwindonShop,
      alt: "Inside the Swindon barber shop",
    },
    text: "Located on Chelmsford Road, in the same building as 'Simple Gym'",
    walkIn: false,
    bookOnline: [
      {
        siteName: "Booksy",
        siteLink:"https://booksy.com/en-gb/69709_wild-barber_barber-shop_1179909_eastleaze"
      }
    ],
    mapLocation: "https://g.page/wild-barber?share"
  },
]


// Array of objects, details about available courses
const listOfCourses = [
  {
    id: 1,
    title: "4 Week Barber Course",
    details: "We have decided to pass on our passion, knowledge, experience, \
    and skills to you. Here at Wild Barber Academy, our core ethics are based on \
    quality knowledge. \n Knowledge is something that can never be taken from you. So, \
    we believe in providing the opportunity to set you up!"
  },
  {
    id: 2,
    title: "1 Day Barber Design Course For Hairdressers",
    details: "We at the Wild Barber Academy, remember and appreciate just how challenging learning \
    a new skill can be. We all make mistakes even after years of experience, so never be afraid \
    to make them when you are first starting out! \n Our 1 Day Course is a great first step into \
    the world of being a Barber."
  },
  {
  id: 3,
    title: "2 Day Advanced Barber Course",
    details: "This course is intended for more experienced Barbers who are looking to \
    expand and elevate their skillset. Or, to provide their clients with a new, fresh experience. \n \
    To give you the best chance to develop, we will be conducting live demonstrations with practical \
    sessions that we found provides the best value."
  }
]







/*---------

    THE APP

---------*/

function App() {

  return (

    // the container for the app
    <Container id='app-container' className='bg-dark p-0' fluid>

      {/* header section */}
      <Header />

      {/* row containing large, artistic interior img */}
      <Row className='m-0'>
        <Image 
        src={HomeIMG}
        alt='interior of his barber shop'
        className='p-0 home-banner'
        />
      </Row>

      {/* section title component for about owner */}
      <SectionTitle sectionID='owner-section' sectionTitle="Little About André" sectionImgSrc={OwnerSRC} sectionImgAlt={"André blow drying a customers hair"}/>
      {/* about owner section */}
      <AboutOwner/>

      {/* section title component for BOOKING section */}
      <SectionTitle sectionID='booking-section' sectionTitle="Book An Appointment" sectionImgSrc={OwnerGirlSRC} sectionImgAlt={"Andre bumping fists with customers daughter."}/>
      {/* booking section */}
      <Booking shops={shopListWithDetails} />


     {/*  <SectionTitle sectionID='gallery-section' sectionTitle='Gallery' sectionImgSrc={OwnerGirlSRC} sectionImgAlt='something something'/>
      <Gallery /> */}



      {/* <SectionTitle sectionID='product-section' sectionTitle='Our Product Line' sectionImgSrc={ProductSectionTitleIMG} sectionImgAlt='four different hair products in a line.'/>
    
      <Products /> */}

      <SectionTitle sectionID='course-section' sectionTitle='View Our Courses' sectionImgSrc={OwnerHairSRC} sectionImgAlt='Andre using hair spray on customer.' />
     
      <Courses courses={listOfCourses}/>
      

      
      {/* footer section */}
      <Footer />

    </Container>
  );
}

export default App;
