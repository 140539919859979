import Row from 'react-bootstrap/Row'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

import { useState } from 'react'

export default function Footer() {
    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)
    const currentYear = new Date().getFullYear()


  return (
    <Row className='mx-0 mt-2 text-white text-center justify-content-center'>
      <Row>
        <h6>
            Copyright © {currentYear} Wild Barber - André Assunção
        </h6>
      </Row>
      <hr className='w-75 mb-2'/>
      <Row className='justify-content-center'>
        <Button variant='secondary' onClick={handleShow} className='w-auto mb-2'>
            Our Socials
        </Button>

        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Links to our Socials </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <a href="https://www.facebook.com/wild.barber1/"
                target='_blank' rel='noreferrer' 
                className=''
                >
                    Wild Barber's FB Page
                    <FacebookIcon />
                </a>

                <br />

                <a href="https://www.instagram.com/wild_barber_/?hl=en-gb" 
                target='_blank' rel='noreferrer'
                className=''
                >
                    Wild Barbers of Swindon's Instagram Page 
                    <InstagramIcon color='secondary'/>
                </a>

                <br />

                

                <a href="https://www.instagram.com/wildbarberacademy/" 
                target='_blank' rel='noreferrer' 
                className=''
                >
                    Wild Barber's Academy Instagram Page
                    <InstagramIcon color='secondary'/>
                </a>


                
            </Modal.Body>
            <Modal.Footer>
            <Button variant="dark" onClick={handleClose}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>
      </Row>
    </Row>
  )
}


/* OLD FOOTER - CLUNKY APPEARANCE
    <Row className='mt-4 mx-0 pb-2 justify-content-center'>
      <Col xs={12} sm={8} className='text-white text-center '>
            <h4>Find Us On The Socials</h4>
            <h5>Facebook</h5>
            <Button variant='secondary'>
            <a href="https://www.facebook.com/wild.barber1/"
            target='_blank' rel='noreferrer' 
            className='text-decoration-none text-white'>
                Wild Barber FB Page
            </a>
            </Button>
            <h5>Instagram</h5>
            <Button variant='secondary'>
            <a href="https://www.instagram.com/wild_barber/" 
            target='_blank' rel='noreferrer'
            className='text-decoration-none text-white'>
                Wild Barbers of Swindon's Insta Page
            </a>
            </Button>
            <Button variant='secondary'>
            <a href="https://www.instagram.com/wild_barber_/" 
            target='_blank' rel='noreferrer' 
            className='text-decoration-none text-white'>
                Wild Barbers of Shaftesbury's Insta Page
            </a>
            </Button>
      </Col>
      <Col xs={12} sm={3} className='text-white text-center'>
      <h6>Created by <a href="https://www.linkedin.com/in/clive-hoadley-302209227/">
                    Clive Hoadley</a>
                </h6>
      </Col>
  </Row>;


*/