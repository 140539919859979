import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'

export default function ShopCard(
    { town, img, text, walkIn, bookOnline, mapLink }) {

    return (
        <Col xs={11} sm={8} md={4} lg={4} xxl={3} className='my-1'>
            <Card>
                <Card.Img variant='top' src={img.src} alt={img.alt}/> 
                <Card.Body className='text-center'>
                    <Card.Title className='text-start'>
                        {town}
                    </Card.Title>
                    <Card.Text className='text-start'>
                        {text}
                    </Card.Text>
                    <Button variant='secondary' className='d-block m-1 mx-auto'>
                        <a href={mapLink} target='_blank' rel='noreferrer' className='text-decoration-none text-white'>
                            Find Us On Google Maps
                        </a>
                    </Button>
                    {walkIn && <p className=''>Feel free to walk in when we're open!</p>}
                    {bookOnline === 'N/A' ? "" : 
                    bookOnline.map((site, index) => {
                        return <Button key={index} variant='secondary' className=''>
                            <a href={site.siteLink} target='_blank' rel='noreferrer' className='text-decoration-none text-white'>
                                Make a Booking with {site.siteName}
                            </a>
                        </Button>
                    })}
                </Card.Body>
            </Card>
        </Col>
    )
}
