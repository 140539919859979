import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'




export default function SectionTitle({ sectionID='none', sectionTitle, sectionImgSrc, sectionImgAlt }) {
  return (
      // row which contains the title for each section & image
    <Row className='mx-0 mt-2 mb-4 text-white px-2'>
        <hr className='w-75 mx-auto my-3'/>
        <Row className='m-0 p-0 justify-content-center'>
            <Col xs={3} md={2} lg={1} className='p-0'>
            <Image 
                id={sectionID}
                src={sectionImgSrc}
                alt={sectionImgAlt}
                fluid
                className='rounded-pill'
            />
            </Col>
            <Col xs={7} md={8} lg={8} className='text-center p-0 align-self-center'>
            <h3 className='mb-0 ml-1'>{sectionTitle}</h3>
            </Col>
        </Row>
        <hr className='w-75 mx-auto my-3'/>
    </Row>
  )
}
