import Row from 'react-bootstrap/Row'
import Accordion from 'react-bootstrap/Accordion'

export default function Courses({ courses }) {
    return (
            /* row containing actual courses */
            <Row className='px-1 mx-0'>
                <Row className='px-1 mx-0'>
                    <p className='text-white text-center'>
                        Below you can see the courses that our Academy has available.
                        If any are of interest to you please don't be afraid to contact us!
                    </p>
                    <p className='text-white text-center'>
                        Phone Number: 07742918351
                        Email Address: wildbarber@hotmail.com
                    </p>
                </Row>
                <Row className='px-1 mx-0'>
                    <Accordion className=''>
                        {courses.map((course) => {
                        return <Accordion.Item key={course.id} eventKey={course.id}>
                            <Accordion.Header>
                                {course.title}
                            </Accordion.Header>
                            <Accordion.Body>
                                {course.details}
                            </Accordion.Body>
                        </Accordion.Item>})}
                    </Accordion>
                </Row>
            </Row>
            
    )
}
