import Row from 'react-bootstrap/Row'
import Image from 'react-bootstrap/Image'

import Andre_Mario from '../assets/images/owner/andre_mario.png'
import Andre_Dad from '../assets/images/owner/andre_dad.png'

export default function AboutOwner() {
  return (
    <Row className='mx-2 border border-white rounded text-white text-center bg-secondary shadow-lg'>
        <div> 
            <Image 
                src={Andre_Mario}
                alt="André with his former teacher Mario Lopes"
                fluid
                className='float-img m-1 p-1'
            /> 
            
            <p className=''>
                <strong className='fs-5'>André</strong>, originally from Portugal. Has been a barber for 
                over 12 years now and his passion for the trade has only grown with time. 
            </p>
            
            <p>
                Inspired by both his father (who is a great barber in his own right!) and by  
                 <strong className=''> Mario Lopes </strong> who he had the pleasure of meeting. 
                Andre has gone on to cut hair in many different countries, finally settling
                in the UK. 
            </p>
                
            <p> 
                He's competed in and won several barber competitions during the course of his career. 
                He's also personally trained and encouraged many of the next generation of barbers to 
                go forth with their art & craft.
            </p> 

            <p>
                His infectious smile, positive attitude and unwavering dedication to his customers has 
                garnered him a lot of respect in the barber community and clients alike. Speaking to 
                him about his vision for his business you cannot help but be inspired to strive forth with 
                your own dreams. 
            </p>     
        </div>
        <div>    
            <Image 
                src={Andre_Dad}
                alt="André cutting his fathers hair with care"
                fluid
                className='float-img dad m-1 p-2'
            />
            <p>
                And now André is striving forth with his <strong>Wild Barber</strong> franchise. The intent 
                is to build a community of passionate Barbers who are dedicated to the craft and also to 
                open up new opportunities for any aspiring Barbers who want to take up the clippers! A couple 
                years in the Wild Barber community has been expanding in the Somerset, and Wiltshire 
                area. The specific locations can be seen just below in the <strong> Book An Appointment</strong> section! 
            </p>

            <p>
                Thank you for checking us out and we hope you have a great day!
            </p>
            </div>
    </Row>
  )
}
